export class MemberDB {
    static members = [
        {
            id: '1',
            approveStatus: 'Askıda',
            phoneNumber: "555-123-23-34",
            email :'asdf@sdfsd.com',
            subscriptionEndDateTime: '2021-02-15T18:52:17.349985',
            subscriptionStartDateTime :'2019-12-08T18:52:17.35013',
            subscriptionName : 'Tam Paket',
            companyName: 'Albatros Yazılım',
            fullName :"FIRAT LOKMAN",
            city : "Adana",
            county :'Seyhan',
            postalCode :"11111",
            fax:'34234234',
            address:'Onur Mah. Turhan Cemal Beriker Bulv. 437 Kiza İş Merkezi Kat:1 No:21',
            vatName :'Ziyapaşa',
            vatNumber :'12132131',
            isActive : true
        },
        {
            id: '2',
            approveStatus: 'Askıda',
            phoneNumber: "555-123-23-34",
            email :'1qweew@sdfsd.com',
            subscriptionEndDateTime: '2021-02-15T18:52:17.349985',
            subscriptionStartDateTime :'2019-12-08T18:52:17.35013',
            subscriptionName : 'Tam Paket',
            companyName: 'Albatros Yazılım',
            city : "Adana",
            county :'Çukurova',
            postalCode :'22222',
            fax:'123123132',
            fullName :"FIRAT LOKMAN",
            address:'Onur Mah. Turhan Cemal Beriker Bulv. 437 Kiza İş Merkezi Kat:1 No:21',
            vatName :'Çukurova',
            vatNumber :'12132131',
            isActive : false
        },
        {
            id: '3',
            approveStatus: 'Onaylandı',
            phoneNumber: "555-123-23-34",
            email :'asdf@sdfsd.com',
            subscriptionEndDateTime: '2021-02-15T18:52:17.349985',
            subscriptionStartDateTime :'2019-12-08T18:52:17.35013',
            subscriptionName : 'Tam Paket',
            companyName: 'Albatros Yazılım',
            city : "Adana",
            county :'Seyhan',
            postalCode :'33333',
            fax:'1231qewq23132',
            fullName :"FIRAT LOKMAN",
            address:'Onur Mah. Turhan Cemal Beriker Bulv. 437 Kiza İş Merkezi Kat:1 No:21',
            vatName :'Seyhan',
            vatNumber :'12132131',
            isActive : true
        }
    ]
}