export class PackageDB {
    static packages = [
        {
            id: '1',
            packageName: 'Deneme',
            packageTime: "1",
            description :'açıklama',
            integrations: ["N11", "Gittigidiyor","Hepsiburada", "Amazon"],
            packageSubUserCount :'1',
            packageFee: '0',
        },
        {
            id: '2',
            packageName: 'Esnaf',
            packageTime: "6",
            description :'açıklama 2',
            integrations: ["N11", "Gittigidiyor"],
            packageSubUserCount :'2',
            packageFee: '49.9',
        },
        {
            id: '3',
            packageName: 'Tam',
            packageTime: "12",
            description :'açıklama 3',
            integrations: ["N11", "Gittigidiyor"],
            packageSubUserCount :'3',
            packageFee: '100',
        }
    ]
}