import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
    private toaster : ToastrService
  ) { }

  canActivate() {
    if (localStorage.getItem('userToken')) {
      return true;
    } else {
      this.toaster.error("Lütfen Giriş Yapınız");
      this.router.navigateByUrl('/sessions/signin');
    }
  }
}
