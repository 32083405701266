export class PaymentDB {
    static payments = [
        {
            id: '1',
            memberId: '1',
            name: "Ali ASGFESRGF",
            cardNumber :'123123123123123',
            installment: '6',
            subscriptionName : 'Tam Paket',
            description :'Açıklama 1',
            isPaymentSuccess : 'Evet',
            processHash : '23123',
            processDateTime :'2019-12-08T18:52:17.35013',
            paymentType :'Havale/EFT',
            totalPrice :'1490'
        },
        {
            id: '2',
            memberId: '2',
            name: "Ali ADA",
            cardNumber :'123123123123123',
            installment: '6',
            subscriptionName : 'Tam Paket',
            description :'Açıklama 1',
            isPaymentSuccess : 'Hayır',
            processHash : '23123',
            processDateTime :'2019-12-08T18:52:17.35013',
            paymentType :'Kredi Kartı',
            totalPrice :'1490'
        },
        {
            id: '3',
            memberId: '3',
            name: "Ali ADRGFGAER",
            cardNumber :'123123123123123',
            installment: '6',
            subscriptionName : 'Tam Paket',
            description :'Açıklama 1',
            isPaymentSuccess : 'Evet',
            processHash : '23123',
            processDateTime :'2019-12-08T18:52:17.35013',
            paymentType :'Havale/EFT',
            totalPrice :'1490'
        }
    ]
}